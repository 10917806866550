import React from "react"
import styled, { css } from "styled-components"
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import { PrimaryTitle } from "src/components/Typography"
import { useSelector } from "react-redux"
import Container from "src/components/Container"
import SectionStoryGrid from "src/components/SectionStoryGrid"
import NoResultsInfo from "src/components/NoResultsInfo"
import { articleNormalizer } from "src/utils/contentNormalizers"
import { get } from "lodash"

export default () => {
  const { searchState, searchResults } = useSelector(state => state.search)
  const parsedHits = get(searchResults, "hits.length")
    ? searchResults.hits.map(articleNormalizer)
    : []

  return (
    <>
      <SEO title="Search" />
      <Wrapper>
        <Header dark={true} />
        <Main scCenter={!parsedHits.length}>
          {parsedHits.length ? (
            <>
              <Container>
                <Title>{`Results for “${searchState.query}”`}</Title>
              </Container>
              <SectionStoryGrid layout="search" items={parsedHits} />
            </>
          ) : (
            <NoResultsInfo />
          )}
        </Main>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 50px;
`

const Main = styled.div`
  min-height: calc(100vh - 100px);
  padding-top: 120px;

  ${p =>
    p.scCenter &&
    css`
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`
